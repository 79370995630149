export const productsColors = {
  //   postcard: [{ color: 'purple', name: '紫藤' }],
  //   orientalcard: [
  //     { color: 'yellow', name: '尊黃' },
  //     { color: 'teal', name: '艾綠' },
  //   ],
  orientalcard: [
    { color: 'purple', name: '紫藤' },
    { color: 'red', name: '赤紅' },
    { color: 'green', name: '竹青' },
  ],
  //   calendars: [
  //     { color: 'yellow', name: '尊黃' },
  //     { color: 'teal', name: '艾綠' },
  //   ],
};
